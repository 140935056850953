type ImageSimple = {
    url: string
    urlLarge: string
}

type SeoFragmentType = {
    title: string
    description: string
    social: {
        twitter: {
            title: string
            description: string
            image: ImageSimple
        }
        facebook: {
            title: string
            description: string
            image: ImageSimple
        }
    }
    advanced: {
        robots: any
        canonical: any
    },
    featuredimage: ImageSimple
}

type FallbackDataType = {
    title: string
    description: string
    siteName: string
    image: string
}

export function useEntrySeo(
    socialFragment: MaybeRefOrGetter<SeoFragmentType>,
    addTitleTemplate?: MaybeRefOrGetter<boolean>
) {
    const { t, locale } = useI18n();

    const siteName = 'Stimuleringsfonds';
    const title = computed(() => toValue(socialFragment).title);

    const fallbackData = computed(() => {
        return {
            title: addTitleTemplate && title.value ? `${title.value} | ${siteName}` : siteName,
            description: toValue(socialFragment)?.description ?? t('seo.description'),
            siteName,
            image: '/social-image.jpg'
        };
    });

    const metaData = computed<{ [key: string]: any }>(() => {
        return {
            title: fallbackData.value.title,
            meta: [
                { hid: 'description', name: 'description', content: fallbackData.value.description },
                { hid: 'author', name: 'author', content: fallbackData.value.siteName },
                { hid: 'og:site_name', name: 'og:site_name', content: fallbackData.value.siteName },
                { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: fallbackData.value.siteName },
                ...getFbFields(toValue(socialFragment), fallbackData.value),
                ...getTwFields(toValue(socialFragment), fallbackData.value),
                getRobots(toValue(socialFragment))
            ],
            htmlAttrs: {
                lang: locale
            }
        };
    });

    watchEffect(() => {
        if (getCanonical(toValue(socialFragment))) {
            metaData.value.link = [
                getCanonical(toValue(socialFragment))
            ];
        }
    });

    useHead(metaData);
    return metaData;
}

function getFbFields(seo: SeoFragmentType, fallbackData: FallbackDataType) {
    if (seo?.social?.facebook) {
        return Object.keys(seo.social.facebook).map((key: string) => {
            // Image
            if (key === 'image' && seo.social.facebook.image) {
                return { hid: 'og:image', property: 'og:image', content: seo.social.facebook[key]?.url };
            } else if (key === 'image' && seo.featuredimage) {
                return { hid: 'og:image', property: 'og:image', content: seo.featuredimage.urlLarge };
            } else if (key === 'image') {
                return { hid: 'og:image', property: 'og:image', content: fallbackData.image };
            }

            // Title
            if (key === 'title' && seo.social.facebook.title) {
                return { hid: 'og:title', property: 'og:title', content: seo.social.facebook.title };
            } else if (key === 'title') {
                return { hid: 'og:title', property: 'og:title', content: fallbackData.title };
            }

            // Description
            if (key === 'description' && seo.social.facebook.description) {
                return { hid: 'og:description', property: 'og:description', content: seo.social.facebook.description };
            } else if (key === 'description') {
                return { hid: 'og:description', property: 'og:description', content: fallbackData.description };
            }

            return { hid: 'og:' + key, property: 'og:' + key, content: seo.social.facebook[key as keyof typeof seo.social.facebook] };
        });
    }

    return [
        { hid: 'og:title', property: 'og:title', content: fallbackData.title },
        { hid: 'og:description', property: 'og:description', content: fallbackData.description },
        { hid: 'og:image', property: 'og:image', content: fallbackData.image }
    ];
}

function getTwFields(seo: SeoFragmentType, fallbackData: FallbackDataType) {
    let data = [];

    if (seo?.social?.twitter) {
        data = Object.keys(seo.social.twitter).map((key: string) => {
            // Image
            if (key === 'image' && seo.social.twitter.image) {
                return { hid: 'twitter:image', name: 'twitter:image', content: seo.social.twitter.image.url };
            } else if (key === 'image' && seo.featuredimage) {
                return { hid: 'twitter:image', property: 'twitter:image', content: seo.featuredimage.urlLarge };
            } else if (key === 'image') {
                return { hid: 'twitter:image', property: 'twitter:image', content: fallbackData.image };
            }

            // Title
            if (key === 'title' && seo.social.twitter.title) {
                return { hid: 'twitter:title', property: 'twitter:title', content: seo.social.twitter.title };
            } else if (key === 'title') {
                return { hid: 'twitter:title', property: 'twitter:title', content: fallbackData.title };
            }

            // Description
            if (key === 'description' && seo.social.twitter.description) {
                return { hid: 'twitter:description', property: 'twitter:description', content: seo.social.twitter.description };
            } else if (key === 'description') {
                return { hid: 'twitter:description', property: 'twitter:description', content: fallbackData.description };
            }

            return { hid: 'twitter:' + key, name: 'twitter:' + key, content: seo.social.twitter[key as keyof typeof seo.social.twitter] };
        });

        data.push({ hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' });

        return data;
    }

    return [
        { hid: 'twitter:title', property: 'twitter:title', content: fallbackData.title },
        { hid: 'twitter:description', property: 'twitter:description', content: fallbackData.description },
        { hid: 'twitter:image', property: 'twitter:image', content: fallbackData.image },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' }
    ];
}

function getRobots(seo: SeoFragmentType) {
    let content = 'all';

    if (seo?.advanced?.robots) {
        content = seo.advanced.robots.join(', ');
    }

    return { hid: 'robots', name: 'robots', content };
}

function getCanonical(seo: SeoFragmentType) {
    if (seo?.advanced?.canonical) {
        return { rel: 'canonical', href: seo.advanced.canonical };
    }

    return false;
}
